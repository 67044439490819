<template>
  <hr v-if="separator" align="left" width="40" class="menu-separator" />
  <b-link
    v-else
    :href="!hasRoute || isSameRoute ? '#' : null"
    :to="hasRoute ? route : null"
    class="menu-item text-uppercase"
    :disabled="disabled || (!hasRoute && !hasClickListener)"
    @click.prevent="onClick"
  >
    <img v-if="iconUrl" :src="iconUrl" />
    {{ label }}</b-link
  >
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  name: 'menu-item',
  props: {
    label: { type: String },
    route: { type: Object },
    separator: { type: Boolean, default: false },
    iconUrl: { type: String },
    disabled: { type: Boolean, default: false }
  },
  computed: {
    hasRoute() {
      return !!this.route
    },
    isSameRoute() {
      return this.hasRoute && this.$router.currentRoute.name == this.route.name
    },
    hasClickListener() {
      return this.$listeners && this.$listeners.click
    }
  },
  methods: {
    ...mapMutations(['hideMenu']),
    onClick() {
      if (this.hasClickListener) {
        this.$listeners.click(...arguments)
      }

      if (this.isSameRoute) {
        this.hideMenu()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles.scss';

.menu-item {
  display: table-cell;
  padding: 15px 0;
  width: 100%;

  text-decoration: none;
  color: #ffffff;
  font-size: 16px;
  font-weight: $font-weight-bold;

  img {
    margin-right: 10px;
  }

  &.disabled {
    color: gray;
  }
}

.menu-separator {
  border: 1px solid #fff;
}
</style>
