var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "menu fill menu-content" },
    [
      _c(
        "div",
        { staticClass: "menu-top" },
        [
          _c(
            "b-container",
            { staticClass: "main-container" },
            [
              _c(
                "b-row",
                { staticClass: "align-items-center" },
                [
                  _c("b-col", [
                    _c(
                      "button",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.isMenuRoute,
                            expression: "isMenuRoute"
                          }
                        ],
                        staticClass: "close",
                        on: {
                          click: function($event) {
                            return _vm.hideMenu()
                          }
                        }
                      },
                      [_c("span", [_vm._v("×")])]
                    )
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c("img", { attrs: { src: require("@/assets/svg/menu-deco.svg") } })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "title-container-wrapper title-sub" },
        [
          _c(
            "b-container",
            { staticClass: "main-container" },
            [
              _c(
                "b-row",
                { staticClass: "align-items-center" },
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-media",
                        [
                          _c("UserPhoto", {
                            attrs: { slot: "aside", size: 70 },
                            slot: "aside"
                          }),
                          _c(
                            "b-row",
                            {
                              staticClass: "title-headline align-items-center"
                            },
                            [
                              _c("b-col", [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.userInfo.fullName))
                                ])
                              ])
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            {
                              staticClass:
                                "title-headline-sub align-items-center"
                            },
                            [
                              _c("b-col", [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.userInfo.username))
                                ])
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "MenuItem",
                        _vm._b(
                          {
                            attrs: {
                              iconUrl: require("@/assets/svg/search.svg")
                            }
                          },
                          "MenuItem",
                          _vm.items[0],
                          false
                        )
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-container",
        {
          staticClass: "menu-content main-container",
          attrs: { id: "menu-entries-container" }
        },
        [
          _vm._l(_vm.items.slice(1), function(item, idx) {
            return _c(
              "b-row",
              { key: idx },
              [
                _c(
                  "b-col",
                  [_c("MenuItem", _vm._b({}, "MenuItem", item, false))],
                  1
                )
              ],
              1
            )
          }),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _c("MenuItem", {
                    attrs: { label: "Déconnexion" },
                    on: { click: _vm.logout }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }