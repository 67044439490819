<template>
  <div class="menu fill menu-content">
    <div class="menu-top">
      <b-container class="main-container">
        <b-row class="align-items-center">
          <b-col>
            <button v-show="isMenuRoute" class="close" @click="hideMenu()">
              <span>&times;</span>
            </button>
          </b-col>
        </b-row>
      </b-container>
      <img src="@/assets/svg/menu-deco.svg" />
    </div>
    <div class="title-container-wrapper title-sub">
      <b-container class="main-container">
        <b-row class="align-items-center">
          <b-col>
            <b-media>
              <UserPhoto :size="70" slot="aside" />
              <b-row class="title-headline align-items-center">
                <b-col>
                  <span>{{ userInfo.fullName }}</span>
                </b-col>
              </b-row>
              <b-row class="title-headline-sub align-items-center">
                <b-col>
                  <span>{{ userInfo.username }}</span>
                </b-col>
              </b-row>
            </b-media>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <MenuItem v-bind="items[0]" iconUrl="@/assets/svg/search.svg" />
          </b-col>
        </b-row>
      </b-container>
    </div>
    <b-container
      id="menu-entries-container"
      class="menu-content main-container"
    >
      <b-row v-for="(item, idx) in items.slice(1)" :key="idx">
        <b-col>
          <MenuItem v-bind="item" />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <MenuItem label="Déconnexion" @click="logout" />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import UserPhoto from '@/components/Account/UserPhoto.vue'
import MenuItem from '@/components/MenuItem.vue'
import { mapMutations } from 'vuex'
import { Routes } from '@/router.js'

export default {
  name: 'app-menu',
  components: { UserPhoto, MenuItem },
  computed: {
    items() {
      return [
        { route: Routes.SEARCH, label: 'Trouver un point de recharge' },
        {
          route: Routes.MY_BOOKINGS,
          label: 'Mes réservations en cours',
          disabled: this.$store.getters.hasNothing
        },
        {
          route: Routes.MY_CHARGING_POINTS,
          label: 'Mes points de recharge',
          disabled: !this.$store.getters.hasChargingPoints
        },
        // { label: 'Mon historique' },
        { separator: true },
        { route: Routes.ACCOUNT, label: 'Mon compte' },
        // { routeName: 'my-complaints', label: 'Mes réclamations' },
        { separator: true },
        { route: Routes.ABOUT, label: 'à propos' },
        { route: Routes.FAQ, label: 'FAQ' }
      ]
    },
    userInfo() {
      return (
        this.$store.state.userInfo || {
          id: '',
          fullName: '',
          email: '',
          username: ''
        }
      )
    },
    isMenuRoute() {
      return this.$router.currentRoute.name != Routes.MENU.name
    }
  },
  methods: {
    ...mapMutations(['hideMenu']),
    logout() {
      console.log('disconnect user')
      this.$authService.logout()
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/styles.scss';

.menu-top {
  background: rgb(25, 205, 216);
  background: linear-gradient(
    270deg,
    rgba(25, 205, 216, 1) 0%,
    rgba(63, 144, 214, 1) 100%
  );

  height: 97px;
  width: 100%;

  .close {
    position: absolute;
    top: 20px;
    left: 20px;
    font-weight: $font-weight-regular;
    font-size: 2em;
    line-height: 13px;
    color: #fff;
    opacity: 1;
  }

  img {
    margin: 0px;
    width: 70%;
    max-width: 500px;
    height: auto;
    position: absolute;
    right: 0;
    z-index: 0;
  }
}

.title-container-wrapper {
  background: none;
  margin-top: -35px;

  .media-body,
  .media-body .title-headline {
    color: #ffffff !important;
  }
}

.title-sub {
  background: rgb(12, 28, 44);
  background: -moz-linear-gradient(
    0deg,
    rgba(12, 28, 44, 1) 75%,
    rgba(12, 28, 44, 0) 75%
  );
  background: -webkit-linear-gradient(
    0deg,
    rgba(12, 28, 44, 1) 75%,
    rgba(12, 28, 44, 0) 75%
  );
  background: linear-gradient(
    0deg,
    rgba(12, 28, 44, 1) 75%,
    rgba(12, 28, 44, 0) 75%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0c1c2c",endColorstr="#0c1c2c",GradientType=1);
  z-index: 1000;
}

.menu-content {
  background-color: $dark;
}

#menu-entries-container {
  background-image: url('~@/assets/img/evenir-logo-x2.png');
  background-position: center bottom;
  background-repeat: no-repeat;
  padding-bottom: 45px;
}

.evenir-logo {
  margin: 0px auto;
  display: block;
  top: 40px;
}
</style>
