var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.separator
    ? _c("hr", {
        staticClass: "menu-separator",
        attrs: { align: "left", width: "40" }
      })
    : _c(
        "b-link",
        {
          staticClass: "menu-item text-uppercase",
          attrs: {
            href: !_vm.hasRoute || _vm.isSameRoute ? "#" : null,
            to: _vm.hasRoute ? _vm.route : null,
            disabled: _vm.disabled || (!_vm.hasRoute && !_vm.hasClickListener)
          },
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.onClick($event)
            }
          }
        },
        [
          _vm.iconUrl ? _c("img", { attrs: { src: _vm.iconUrl } }) : _vm._e(),
          _vm._v(" " + _vm._s(_vm.label))
        ]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }